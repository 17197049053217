@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
    --gap: 40px;
    --aside: 15%;
    --green: #6DD400;
    --blue: #006CBF;
    --grey: #CCC;
    --grid-color: rgba(255,255,255,.1);
    --qtrans: all .5s cubic-bezier(0.54, 0.22, 0.22, 0.97);
    --strans: all 1s cubic-bezier(0.54, 0.22, 0.22, 0.97);
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    line-height: 1.5em;
    background: #000;
    color: #FFF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b, strong {
    font-weight: 700;
}

h1 {
    position: relative;
    font-size: 2em;
    line-height: 1em;
    font-weight: 400;
}
h1 a {
    color: #FFF;
    text-decoration: none;
}
h3, .h3 {
    font-size: 1em;
    margin: .5em 0 .5em 0;
    font-weight: 700;
}
h1 span {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: .4em;
}
p {
    margin: 0 0 1em 0;
}
a {
    color: var(--green);
    text-decoration: underline;
    text-decoration-thickness: 1px;
    transition: var(--qtrans);
}
a:hover {
    color: #FFF;
}
.wrapper {
    padding: 7% 5% 4% 5%;
}

.header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 5em 0;
}
.header__menu {
    list-style: none;
    display: flex;
    font-weight: 700;
}
.header__menu li {
    margin: 0 1em 0 0;
}
.header__menu a {
    display: block;
    line-height: 2rem;
    padding: 0 1em;
    color: #FFF;
    text-decoration: none;
    border-radius: 2rem;
    border: 2px solid transparent;
}
.header__menu a:hover {
    border-color: #FFF;
}
.header__menu a.active {
    background: #FFF;
    border-color: #FFF;
    color: #000;
}

.cv_columns {
    display: grid;
    grid-template-columns: var(--aside) 1fr;
    gap: var(--gap);
}
.cv_columns__image {
    /* display: flex; */
    /* height: 120px; */
    /* justify-content: center; */
    /* align-items: center; */
}
.cv_columns__image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 80px;
}
.cv_columns__description__title {
    font-weight: 700;
    margin: 0 0 .5em 0;
}
.cv_columns__description__website {
    margin: 0 0 .5em 0;
}

.work_experience {
    display: grid;
    gap: 1em;
}
.work_experience__single__role,
.cv_columns__description__tech {
    font-size: .75em;
}
.work_experience__single__date {
    color: var(--grey);
}

.key_features {
    list-style: none;
}
.key_features li {
    position: relative;
    list-style: none;
    padding: 0 0 .5em 1.5em;
}
.key_features li::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1em;
    height: 1px;
    top: .65em;
    background: var(--green);
}

.footer {
    display: flex;
    justify-content: space-between;
    margin: 4em 0 0 0;
}

@media screen and (max-width: 950px) {
    .header__menu a span {
        display: none;
    }
}
@media screen and (max-width: 720px) {
    :root {
        --gap: 20px;
    }
    .header {
        justify-content: initial;
        flex-direction: column;
        margin: 0 0 1.5em 0;
    }
    h1 {
        margin: 0 0 .75em 0;
    }
    h1 span {
        display: block;
        position: static;
        left: 0;
        top: auto;
    }
    .cv_columns {
        display: block;
    }
    .cv_columns>strong {
        display: block;
        font-size: 1.5em;
        line-height: 1.5em;
        padding: 1em 0 .5em 0;
    }
    .cv_columns__image img {
        max-width: 160px;
    }
}
@media screen and (max-width: 620px) {
    .footer {
        justify-content: initial;
        flex-direction: column;
        gap: .5em;
    }
}